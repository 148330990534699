import { RoutePermissionsModel } from '@shared/model/route-permissions.model';
import {
  AppAccessEnum,
  ServicesAndRequestsAccess,
  SystemAdminAccessEnum,
  WeaponWarehousesAccessEnum,
} from '@shared/enums/app-access.enum';

export const LAYOUT_ROUTES = {
  userData: 'user-data',
  accessControl: 'UserPermissions',
  taskManagement: 'Tasks',
  securityPermissions: {
    root: 'SecurityPermissions',
    list: 'List',
  },
  securityReports: 'SecurityReports',
  securityNotes: {
    root: 'SecurityNotes',
    list: 'List',
    edit: 'Edit',
    details: 'Details',
  },
  contractors: 'Contractors',
  securityIncidents: 'SecurityIncidents',
  shiftsManagement: 'ShiftsManagement',
  news: 'news',
  statistics: 'dashboard',
  dropdowns: 'dropdowns',
  feedback: 'feedback',
  locationsManagement: 'locations-management',
  delegations: {
    root: 'delegations',
    edit: 'edit',
    details: 'details',
  },
  servicesAndRequests: {
    root: 'ServicesAndRequests',
    maintenance: {
      root: 'MaintenanceRequest',
      list: 'list',
      details: 'Details',
      add: 'add',
    },
    maintenanceDepartment: 'maintenance-department',
    locations: {
      root: 'NewLocation',
      list: 'list',
      add: 'add',
      details: 'Details',
    },
  },
  weapons: {
    root: 'Weapons',
    myTasks: 'my-tasks',
    gateArmingRequest: {
      root: 'GateArmingRequest',
      add: 'add',
      edit: 'edit',
      details: 'Details',
    },
    weaponsFormsManagement: {
      root: 'weapons-forms-management',
      add: 'add',
      edit: 'edit',
    },
    weaponsForms: {
      root: 'weapons-forms',
    },
    menArmingRequests: {
      root: 'SecurityManArmingRequest',
      add: 'add',
      details: 'Details',
      edit: 'edit',
    },
    securityMenManagement: {
      root: 'SecurityMenManagement',
      details: 'Details',
      tasks: 'tasks',
      edit: 'edit',
    },
    settings: {
      root: 'settings',
      courses: 'training-courses-validity',
      notifications: 'notifications',
    },
    safes: {
      root: 'safes',
      add: 'add',
      edit: 'edit',
    },
    safesHandover: {
      root: 'safes-handover',
    },
    statistics: {
      root: 'statistics',
      safesPerLocation: 'safes-per-location',
      weaponsPerGate: 'weapons-per-gate',
    },
  },
  systemAdmin: 'system-admin',
} as const;
export const LAYOUT_ROUTES_PERMISSIONS: RoutePermissionsModel<
  typeof LAYOUT_ROUTES
> = {
  userData: null,
  accessControl: {
    code: AppAccessEnum.ac_AccessManagement,
  },
  taskManagement: null,
  securityPermissions: {
    root: {
      code: 'sp_',
      fullMatch: false,
      enableRedirect: false,
    },
    list: null,
  },
  securityReports: {
    code: 'sr_',
    fullMatch: false,
    enableRedirect: false,
  },
  securityNotes: {
    root: {
      code: 'sn_',
      fullMatch: false,
      enableRedirect: false,
    },
    list: null,
    details: null,
    edit: null,
  },
  contractors: {
    code: AppAccessEnum.ContractorUsers_Contractor_Read,
  },
  securityIncidents: {
    code: [
      ServicesAndRequestsAccess.si_Incident_View,
      ServicesAndRequestsAccess.si_Incident_Post,
      ServicesAndRequestsAccess.si_OperationRoom_Management,
    ],
  },
  shiftsManagement: {
    code: 'sm_',
    fullMatch: false,
    enableRedirect: false,
  },
  news: {
    code: AppAccessEnum.SystemSettings_News_Manage,
  },
  statistics: {
    code: [
      AppAccessEnum.sts_General_View,
      AppAccessEnum.sts_PermissionRequests_View,
      AppAccessEnum.sts_SecurityReports_View,
      AppAccessEnum.sts_SecurityNotes_View,
    ],
  },
  dropdowns: {
    code: AppAccessEnum.SystemSettings_DropDowns_Manage,
  },
  feedback: {
    code: AppAccessEnum.SystemSettings_Feedbacks_Manage,
  },
  locationsManagement: {
    code: [
      WeaponWarehousesAccessEnum.Read,
      AppAccessEnum.lm_Location_Managment,
    ],
  },
  delegations: {
    root: {
      code: AppAccessEnum.DelegationList,
    },
    edit: null,
    details: null,
  },
  servicesAndRequests: {
    root: null,
    maintenance: {
      root: {
        code: [
          ServicesAndRequestsAccess.ssr_MaintenanceRequest_View,
          ServicesAndRequestsAccess.ssr_MaintenanceRequest_Post,
        ],
      },
      list: {
        code: ServicesAndRequestsAccess.ssr_MaintenanceRequest_View,
      },
      add: {
        code: ServicesAndRequestsAccess.ssr_MaintenanceRequest_Post,
      },
      details: null,
    },
    maintenanceDepartment: {
      code: ServicesAndRequestsAccess.ssr_MaintenanceDepartment_Post,
    },
    locations: {
      root: {
        code: [
          ServicesAndRequestsAccess.ssr_NewLocationRequest_View,
          ServicesAndRequestsAccess.ssr_NewLocationRequest_Post,
        ],
      },
      list: {
        code: ServicesAndRequestsAccess.ssr_NewLocationRequest_View,
      },
      add: {
        code: ServicesAndRequestsAccess.ssr_NewLocationRequest_Post,
      },
      details: null,
    },
  },
  weapons: {
    root: {
      code: AppAccessEnum.WeaponsModule,
      fullMatch: false,
      enableRedirect: false,
    },
    myTasks: null,
    gateArmingRequest: {
      root: null,
      add: null,
      edit: null,
      details: null,
    },
    statistics: {
      root: null,
      safesPerLocation: null,
      weaponsPerGate: null,
    },
    menArmingRequests: {
      root: null,
      details: null,
      edit: null,
      add: null,
    },
    safesHandover: {
      root: null,
    },
    securityMenManagement: {
      root: null,
      edit: null,
      details: null,
      tasks: null,
    },
    weaponsForms: {
      root: null,
    },
    settings: {
      root: null,
      courses: null,
      notifications: null,
    },
    safes: {
      root: null,
      edit: null,
      add: null,
    },
    weaponsFormsManagement: {
      root: null,
      add: null,
      edit: null,
    },
  },
  systemAdmin: {
    code: SystemAdminAccessEnum.ac_SystemAdminManagement_List,
  },
};
